import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

    export default class PendingPrintService
    {
        #api = null;    
        constructor() {
            this.#api = API_URL + 'admin/pending';
        }

        preparePracticalReports(type, data){
            let url = `${this.#api}/practical-exam/report/print/${type}`;
            return apiService.post(url, data);
        }

        preparePracticalCertificates(type, data){
            let url = `${this.#api}/practical-exam/certificate/print/${type}`;
            return apiService.post(url, data);
        }

        preparePracticalNotifications(type, data){
            let url = `${this.#api}/practical-exam/notification/print/${type}`;
            return apiService.post(url, data);
        }

        preparePracticalPplateCertificates(type, data){
            let url = `${this.#api}/pplate-practical-exam/certificate/print/${type}`;
            return apiService.post(url, data);
        }

        preparePracticalLabels(type, data){
            let url = `${this.#api}/practical-exam/label/print/${type}`;
            return apiService.post(url, data);
        }

        prepareOnlineLabels(type, data){
            let url = `${this.#api}/online-exam/label/print/${type}`;
            return apiService.post(url, data);
        }

        prepareOnlineCertificates(type, data){
            let url = `${this.#api}/online-exam/certificate/print/${type}`;
            return apiService.post(url, data);
        }

        prepareAwardCertificates(type, data){
            let url = `${this.#api}/ameb-award/certificate/print/${type}`;
            return apiService.post(url, data);
        }
        
        prepareAwardLabels(type, data){
            let url = `${this.#api}/ameb-award/label/print/${type}`;
            return apiService.post(url, data);
        }

        prepareTimetables(type, data){
            let url = `${this.#api}/timetable/print/${type}`;
            return apiService.post(url, data);
        }

        exportCsvPrintJobs(data){
            let url = `${this.#api}/export/csv?candidate_ids=${data}`;
            window.open(url, "_blank")
        }

        printLabels(data){
            let url = `${this.#api}/label/print`;
            return apiService.post(url, data);
        }
    }
